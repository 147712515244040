export default {
  BUTTON_PRIMARY_CLASS: 'cm_button button button--primary theme-btn-primary',
  BUTTON_SECONDARY_CLASS: 'cm_button button button-secondary theme-btn-secondary',

  SEARCH_BOX_PLACEHOLDER: 'Search the store',
  SEARCH_BOX_CLASS: 'form quick-search',
  SEARCH_BOX_FORM_CLASS: 'form-fieldset',
  SEARCH_BOX_INPUT_CLASS: 'form-input quick-search-input',

  GARAGE_BUTTON_CLASS: 'navUser-action',
  GARAGE_SIZE: '<span class="countPill show" key="garage-size">{{size}}</span>',

  FACET_INPUT_CLASS: 'form-input',
  FACET_BUTTON_CLASS: 'button button--small theme-btn-primary',
  MIN_PRICE: 'Min',
  MAX_PRICE: 'Max',

  PRODUCT_REPEATER_CLASS: 'productGrid',

  SELECT_YOUR_VEHICLE: 'Select Your <span>Vehicle</span>',
  VEHICLE_WIDGET_COLLAPSIBLE_CLASS: 'container',
};
