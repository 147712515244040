//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-720:_456,_4351,_3092,_6084,_2492,_2912,_5280,_7616;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-720')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-720', "_456,_4351,_3092,_6084,_2492,_2912,_5280,_7616");
        }
      }catch (ex) {
        console.error(ex);
      }