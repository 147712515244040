import bigcommerceSparkDefaults from '../bigcommerce-elevate/config.js';

bigcommerceSparkDefaults.Widgets.find((w) => w.name === 'HomeVehicleWidget').location = {
  firstChildOf: 'body.default header ~ .body',
  class: 'cm_vehicle-widget__home_container',
};

export default {
  ...bigcommerceSparkDefaults,
  includes: ['bigcommerce-elevate'],
};
